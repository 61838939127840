.app__resume {
    flex-direction: column;
    padding-top: 5rem;
    padding-bottom: 1.5rem;
    font-family: 'Roboto', serif;

    > div {
        max-width: 50%;
    }

    h1, h2, h3, h3, h4, h5, h6 {
        margin: 0.7rem 0;

        span {
            color: var(--secondary-color);
            font-size: 1rem;
        }
    }

    p {
        margin: 0.5rem;
    }

    @media screen and (max-width: 450px) {
        > div {
            max-width: 80%;
        }
    }

    @media screen and (min-width: 1200px) {
        h1 {
            font-size: 2.2rem;
        }
        h3 {
            font-size: 1.8rem;
        }
        p {
            font-size: 1.5rem;
        }
    }
}

.core-skills {
    font-weight: 800;
}

.app__resume-education, .app__resume-experience, .app__resume-skills {
    width: 100%;
    border-bottom: 2px solid var(--secondary-color);
}

.app__resume-certifications {
    width: 100%;
}

.app__resume-download {
    list-style: none;
    font-size: large;
}